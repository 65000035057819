(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "ramda", "../type"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("ramda"), require("../type"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.ramda, global.type);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _ramda, _type) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assert = assert;
  _exports.assertArray = assertArray;
  _exports.assertBoolean = assertBoolean;
  _exports.assertDateTime = assertDateTime;
  _exports.assertError = assertError;
  _exports.assertFunction = assertFunction;
  _exports.assertHTMLElement = assertHTMLElement;
  _exports.assertImage = assertImage;
  _exports.assertInteger = assertInteger;
  _exports.assertInterval = assertInterval;
  _exports.assertLooseArray = assertLooseArray;
  _exports.assertLooseBoolean = assertLooseBoolean;
  _exports.assertLooseFunction = assertLooseFunction;
  _exports.assertLooseNumber = assertLooseNumber;
  _exports.assertLooseObject = assertLooseObject;
  _exports.assertLooseString = assertLooseString;
  _exports.assertNonEmptyArray = assertNonEmptyArray;
  _exports.assertNotEmpty = assertNotEmpty;
  _exports.assertNotEmptyString = assertNotEmptyString;
  _exports.assertNumber = assertNumber;
  _exports.assertObject = assertObject;
  _exports.assertPositiveInteger = assertPositiveInteger;
  _exports.assertString = assertString;
  _exports.assertValidDateTime = assertValidDateTime;
  _exports.assertValidInterval = assertValidInterval;
  _exports.baseAssert = baseAssert;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  /*
   |------------------------------------------------------------------------------
   | Partial
   |------------------------------------------------------------------------------
   */

  /**
   * Throw an exception if a condition is not met. `value` is passed to
   * `condition` to check if the condition is met.
   * It is the base (or left-most) function for partial applications: do not use
   * it directly.
   * @param   {Function} condition - Condition to meet. Must return true to be
   * validated.
   * @param   {string} message - Printed error message
   * @param   {any} value - Value to pass to `condition`
   * @param   {string} [name] - A string (typically a name) prefixing `message`
   * @returns {undefined}
   *
   * @throws Will throw an exception if `condition` and `message` do not match the
   * required types
   */
  function baseAssert(condition, message, value, name) {
    if (typeof condition !== "function") {
      throw new TypeError("`condition` must be a function");
    }
    if (typeof message !== "string") {
      throw new TypeError("`message` must be a string");
    }
    var error = name ? "`".concat(name, "` ").concat(message) : message;
    if (!condition(value)) {
      throw new TypeError("".concat(error, ". Type is `").concat(_typeof(value), "` and value `").concat(value, "`"));
    }
  }

  /*
   |------------------------------------------------------------------------------
   | Native Javascript
   |------------------------------------------------------------------------------
   */

  /**
   * Throw an exception if parameter is falsy
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assert(value, name) {
    baseAssert(_ramda.identity, "must be truthy", value, name);
  }

  /**
   * Throw an exception if parameter is empty. See https://ramdajs.com/docs/#isEmpty.
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertNotEmpty(value, name) {
    baseAssert((0, _ramda.complement)(_ramda.isEmpty), "can't be empty", value, name);
  }

  /**
   * Throw an exception if parameter is not a boolean
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertBoolean(value, name) {
    baseAssert(_type.isBoolean, "must be a boolean", value, name);
  }

  /**
   * Throw an exception if parameter is not a number
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertNumber(value, name) {
    baseAssert(_type.isNumber, "must be a number", value, name);
  }

  /**
   * Throw an exception if parameter is not an integer
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertInteger(value, name) {
    baseAssert(_type.isInteger, "must be an integer", value, name);
  }

  /**
   * Throw an exception if parameter is not a positive integer
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertPositiveInteger(value, name) {
    assertInteger(value, name);
    if (value < 0) {
      var message = "must be a positive integer";
      var error = name ? "`".concat(name, "` ").concat(message) : message;
      throw new Error("".concat(error, ". Value is ").concat(value));
    }
  }

  /**
   * Throw an exception if parameter is not a string
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertString(value, name) {
    baseAssert(_type.isString, "must be a string", value, name);
  }

  /**
   * Throw an exception if parameter is not a string or is empty
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertNotEmptyString(value, name) {
    assertString(value, name);
    assertNotEmpty(value, name);
  }

  /**
   * Throw an exception if parameter is null or its type is not `object`
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertObject(value, name) {
    baseAssert(_type.isObject, "must be an object", value, name);
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertArray(value, name) {
    baseAssert(_type.isArray, "must be an array", value, name);
  }

  /**
   * Throw an exception if parameter is not an array or is empty
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertNonEmptyArray(value, name) {
    assertArray(value, name);
    assertNotEmpty(value, name);
  }

  /**
   * Throw an exception if parameter is not a function
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertFunction(value, name) {
    baseAssert(_type.isFunction, "must be a function", value, name);
  }

  /**
   * Throw an exception if parameter is not an instance of Error
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertError(value, name) {
    baseAssert(_type.isError, "must be an instance of Error", value, name);
  }

  /*
   |------------------------------------------------------------------------------
   | Browser
   |------------------------------------------------------------------------------
   */

  /**
   * Throw an exception if parameter is not an image
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertImage(value, name) {
    baseAssert(_type.isImage, "must be an image", value, name);
  }

  /**
   * Throw an exception if parameter is not an HTML element
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertHTMLElement(value, name) {
    baseAssert(_type.isHTMLElement, "must be an HTML element", value, name);
  }

  /*
   |------------------------------------------------------------------------------
   | Luxon
   |------------------------------------------------------------------------------
   */

  /**
   * Throw an exception if parameter is not an instance of luxon's DateTime
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertDateTime(value, name) {
    baseAssert(_type.isDateTime, "must be an instance of DateTime", value, name);
  }

  /**
   * Throw an exception if parameter is not an instance of luxon's Interval
   * @param   {any} value - Value to check
   * @param   {string} [name] - Display name of the parameter
   * @returns {undefined}
   */
  function assertInterval(value, name) {
    baseAssert(_type.isInterval, "must be an instance of Interval", value, name);
  }

  /**
   * Throw an exception if parameter is not a valid instance of luxon's DateTime
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertValidDateTime(value, name) {
    assertDateTime(value, name);
    if (!value.isValid) {
      var message = "must be a valid DateTime";
      var error = name ? "`".concat(name, "` ").concat(message) : message;
      throw new Error("".concat(error, ". ").concat(value.invalidExplanation));
    }
  }

  /**
   * Throw an exception if parameter is not a valid instance of luxon's Interval
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertValidInterval(value, name) {
    assertInterval(value, name);
    if (!value.isValid) {
      var message = "must be a valid Interval";
      var error = name ? "`".concat(name, "` ").concat(message) : message;
      throw new Error("".concat(error, ". ").concat(value.invalidExplanation));
    }
  }

  /*
   |------------------------------------------------------------------------------
   | Loose assertions
   |------------------------------------------------------------------------------
   */

  /**
   * Throw an exception if parameter is not a boolean, unless it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseBoolean(value, name) {
    if (typeof value !== "undefined") {
      assertBoolean(value, name);
    }
  }

  /**
   * Throw an exception if parameter is not a number, unless it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseNumber(value, name) {
    if (typeof value !== "undefined") {
      assertNumber(value, name);
    }
  }

  /**
   * Throw an exception if parameter is not a string, unless it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseString(value, name) {
    if (typeof value !== "undefined") {
      assertString(value, name);
    }
  }

  /**
   * Throw an exception if parameter is null and its type is not `object`, unless
   * it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseObject(value, name) {
    if (typeof value !== "undefined") {
      assertObject(value, name);
    }
  }

  /**
   * Throw an exception if parameter is not an array, unless it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseArray(value, name) {
    if (typeof value !== "undefined") {
      assertArray(value, name);
    }
  }

  /**
   * Throw an exception if parameter is not a function, unless it's undefined
   * @param   {any} value - Value to check
   * @param   {string} name - Display name of the parameter
   * @returns {undefined}
   */
  function assertLooseFunction(value, name) {
    if (typeof value !== "undefined") {
      assertFunction(value, name);
    }
  }
});