/* @flow */

import * as Sentry from '@sentry/react';

import { assertObject } from '@braindate/util/lib/assert';

import { getSentryOptions } from 'src/shared/app/base/util/errorReportingUtils';
import type { Store } from 'src/shared/core/type/reduxTypes';

/**
 * Initialize the error reporting service
 * @returns {undefined}
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function initErrorReporting(): void {
  Sentry.init(getSentryOptions());
}

/**
 * Add the app state to each event sent to the error reporting service
 * @param   {Store} store - Redux store
 * @returns {undefined}
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function enhanceErrorReportingWithState(store: Store): void {
  assertObject(store, 'store');

  Sentry.configureScope((scope) => {
    scope.addEventProcessor((event) => {
      event.extra = { state: store.getState() };

      return event;
    });
  });
}

/**
 * Report an exception to the error reporting service
 * @param   {Error} e - Exception to report
 * @returns {undefined}
 */
export function captureException(e: Error): void {
  if (e instanceof Error) {
    Sentry.captureException(e);
  } else {
    const error = new Error();
    error.name = 'Unknown error';
    error.message = JSON.stringify(e);
    Sentry.captureException(error);
  }
}
